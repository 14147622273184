<!--
 * @Author: your name
 * @Date: 2020-04-23 16:12:30
 * @LastEditTime: 2020-12-03 10:01:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/useHelp/index.vue
-->

<template>
  <div class="preview-news">
    <div class="main">
      <div class="left">
        <h3>打通高校科研与市场“隔断” 服务社会高质量发展</h3>
        <div class="news-setting">
          <h3 class="time">
            发布时间： 2019-01-09
          </h3>
          <!-- <h5 class="num">
            浏览量： 28289
          </h5> -->
        </div>
        <div class="img-box" style="margin-top:40px">
          <img src="../../assets/images/news/news15/news1.png" alt="">
        </div>
        <div class="news-content" style="margin:20px 0 40px">
          <p style="text-align:justify;">
            全省教育大会是湖南省近年来最高规格的一次教育盛会，会上深入学习贯彻习近平总书记关于教育的重要论述和全国教育大会精神，全面总结和部署全省教育工作。会后，社会各界反响热烈。时刻新闻记者通过前往长沙、湘潭、张家界、娄底等地，走访幼儿园、特殊教育学校、中小学、高校等单位。即日起，推出【“湘”约教育新时代】后续专题报道，体现各级各部门贯彻落实大会精神的思路打算和行动成效。
          </p>
          <p style="text-align:justify;">
            当前，湖南省正大力实施创新引领开放崛起战略，处于以创新推动高质量发展的关键时期，高校要主动融入、积极作为，全面提升科技创新能力和人才培养水平，更好地服务经济社会发展。湖南省高校纷纷围绕市场需求、围绕产业链来打造创新链，健全协同创新、技术转移、成果转化等产学研一体化创新机制，力争在未来不久的日子里打通高校科研成果与企业、市场之间的“隔断”。
          </p>
          <p style="text-align:justify;font-weight:600;">
            创业激发创新动力
          </p>
          <p style="text-align:justify;">
            2018年5月，中南大学科技园（研发）总部盛大开园。科技园以“建好大学科技园，探索建立集基础研究、应用开发、成果转移转化于一体的研发平台”为要求，积极搭建公共研发平台。目前，已完成了15家企业入驻科技园（研发）总部的合同签订。
          </p>
          <p style="text-align:justify;">
            以服务学校双一流建设为己任，以“一园多区”总体布局为指导，形成了“实验室、学科、园区”的三维一体发展路径。转化所得效益反哺学科促进研究开展，实现无限动能循环。
          </p>
          <p style="text-align:justify;">
            湖南新云网科技有限公司是进驻科技园的第一家单位，该公司致力于转化张尧学院士的“透明计算”理论系列科技成果，透明计算获得2014年度国家自然科学奖一等奖。“打造教育信息系统生态圈，实现了教学信息化的自然生态圈。”该公司推出的“云智教”包括了智慧校牌、智慧课桌和智慧黑板等。其中，智慧课桌为学生提供个人学习智能化终端，长期采集每位学生的学习行为数据，有助于实现信息化时代的因材施教，智慧黑板则在保留传统板书书写功能的同时，提供更生动形象的多媒体教学资源和备授课工具。
          </p>
          <p style="text-align:justify;">
            “我们将‘透明计算’技术运用到了公司产品当中，这就是公司在市场上最具核心竞争力的部分。”新云网副总经理杨建国说，“搬到这边以后，有着得天独厚的地理优势，可以共享学校科研资源，更加缩短了公司产品的研发时间和与专家的交流时间，提高了工作效率。”
          </p>
          <p style="text-align:justify;font-weight:600;">
            保持核心竞争力
          </p>
          <p style="text-align:justify;">
            1月8日，2018年度国家科学技术奖励大会在北京举行，湖南大学3项科研成果获奖，包括作为第一完成单位获奖2项，作为参与单位获奖1项。其中王耀南教授主持完成的“高端制药机器人视觉检测与控制关键技术及应用”项目获国家技术发明二等奖。
          </p>
          <p style="text-align:justify;">
            湖南大学机器人视觉感知与控制技术国家工程实验室包括多个用于科研、教学和示范演示的机器人智能制造自动化生产线。其中， “高端制药机器人视觉检测与控制关键技术及应用”项目的相关成果成功应用于国内外多家制药企业，取得显著经济社会效益。授权发明专利 54 项，制定国家行业标准 7 项。
          </p>
          <p style="text-align:justify;">
            “高端智能制造机器人自动化作业生产线”集成了多目三维视觉机器人引导等多项技术，实现针对大型复杂异形多面体精密金属工件的全自动上料、物料的自动转运等，按照工业4.0标准实现全自动的柔性智能制造。其关键技术已经应用于东风日产、东风本田、广汽本田、柳州五菱等大型汽车生产线上。
          </p>
          <p style="text-align:justify;">
            围绕湖南省20个新兴优势产业链、长沙市22个新兴优势产业链、湘江新区“湘江智谷”、湖南8个国家级高新区、岳麓山大学科技城、岳麓山实验室等的政策，湖南大学不断推动产学研合作，拓展服务地方范围。湖南大学校长助理李树涛表示：“依托机械、化工、电气等优势学科，湖大在智能装备制造、新材料、节能环保等领域形成了具有核心竞争力的高新技术。接下来，湖南大学将一如既往以服务三湘大地为己任，聚焦全省经济社会发展重大现实问题，着力培养一批又一批高素质人才，搭建一批高水平创新平台，形成一批产学研用结合的重要成果。
          </p>
        </div>

        <div class="pagination">
          <div class="a-more">
            <span @click="$router.push('/indexOs/newsDetails14')">上一条：推进科研成果转化 培养高素质人才</span>
            <span @click="$router.push('/indexOs/newsDetails16')">下一条：岳麓山国家大学科技城：有人又有才 科创平台跑起来</span>
          </div>
          <el-button plain @click="backGo()">
            返回
          </el-button>
        </div>
      </div>
      <!-- 搜索区域 -->
      <!-- <div class="search-right">
        <el-input
          v-model="keyWord"
          placeholder="查询你喜欢的内容"
          suffix-icon="el-icon-search"
        />
        <div class="hot-news">
          <div class="hot-title">
            <span>最热文章</span>
            <i class="hot-icon" />
          </div>
          <div class="lb-news">
            <router-link class="dg-news" to="/indexOs/newsDetails1">
              <img src="../../assets/images/news/news2-1.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  中共中央政治局常委、国务院副总理 韩正来我公司视察
                </h3>
                <h5 class="time">
                 2020-06-01
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails2">
              <img src="../../assets/images/news/news2-2.png">
              <div class="news-introduce">
                <h3 class="news-title">
                    岳麓山大学科技城：强化科技人才支撑 打造湖南创新...
                </h3>
                <h5 class="time">
                  2020-06-03
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails3">
              <img src="../../assets/images/news/news2-3.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  大科城软件产业联盟拍了拍
                </h3>
                <h5 class="time">
                  2020-08-03
                </h5>
              </div>
            </router-link>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewNews',
  data() {
    return {
      keyWord: ''
    };
  },
  methods: {
    backGo() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1400px) {
.preview-news{
  width: 100%;
  height: auto;
  background: #F5F5F5;
  .main{
    width: 800px;
    height: auto;
    margin: 0 auto;
    display: flex;
    padding-top: 58px;
    justify-content: space-between;
    .left{
      flex: 1;
      margin-right: 63px;
      h3{
        color: #313131;
        font-size:24px;
        font-weight: bolder;
        text-align: center;
      }
      .news-setting{
        color: #B5B5B5;
        display: flex;
         font-size:18px;
        .time{
          font-size:18px;
          color: #B5B5B5;
          font-weight: 100;
          width: 100%;
          text-align: center;
        }
        .num{
          font-size:18px;
          margin-left: 30px;
          font-weight: 100;
        }
      }
      .news-content{
        p{
          font-size: 18px;
          color: #313131;
          line-height: 32px;
          text-indent: 36px;
        }
        .img-explain{
          text-align:justify;
          text-align: center;
          font-size:16px;
        }
      }
      .img-box{
        width: 100%;
    display: flex;
    justify-content: center;
        img{
          width:800px;
        }
      }
    }
    .pagination{
      display: flex;
      justify-content: space-between;
      padding: 50px 0 150px;
      border-top: 1px solid #DDDDDD;
      .a-more{
        display: flex;
        flex-direction: column;
        font-size:18px;
        line-height:32px;
        color:rgba(49,49,49,1);
        span{
          display: inline-block;
          width:600px;
          white-space: nowrap;
          text-overflow:ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
      }
      .el-button{
        width: 134px;
        height: 48px;
        border: 1px solid rgba(253,156,2,1);
        opacity: 1;
        border-radius: 10px;
        font-size:24px;
        color:rgba(253,156,2,1);
      }
    }
    // 搜索区域
    .search-right{
      width: 334px;
      .el-input{
        width: 329px;
        height: 45px;
      }
      .el-input__inner{
        border-radius: 5px;
      }
      .el-input__icon{
        font-size: 24px;
      }
      .hot-news{
        .hot-title{
          font-size:24px;
          margin-bottom: 12px;
          color:#313131;
          .hot-icon{
            display: inline-block;
            background: url('../../assets/images/news/hot.png');
            width: 18px;
            height: 22px;
            margin-left: 6px;
          }
        }
        .dg-news{
          display: flex;
          margin-bottom: 22px;
          img{
            width:132px;
            height:76px;
          }
          .news-introduce{
            flex: 1;
            margin-left: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .news-title{
              font-size:16px;
              font-weight: 600;
              color: #313131;
              box-sizing: border-box;
              text-overflow:ellipsis;
              display:-webkit-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;
              overflow: hidden;
            }
            .time{
              font-size:18px;
              color: #B5B5B5;
              font-weight: 100;
            }
          }
          &:hover{
            .news-title{
              color:rgba(253,156,2,1);
            }
          }
        }
      }
    }
  }
}
}
@media screen and (max-width: 1400px) {
  .preview-news{
    width: 100%;
    height: auto;
    background: #fff;
    .main{
      width: 100%;
      height: auto;
      display: flex;
      padding-top: 1rem;
      justify-content: space-between;
      padding:1.07rem .48rem;
      .left{
        flex: 1;
        h3{
          color: #313131;
          font-size:.48rem;
          font-weight: bolder;
          text-align: center;
        }
        .news-setting{
          color: #B5B5B5;
          display: flex;
          font-size:18px;
          .time{
            font-size:.267rem;
            color: #B5B5B5;
            font-weight: 100;
            width: 100%;
            text-align: center;
          }
          .num{
            font-size:18px;
            margin-left: 30px;
            font-weight: 100;
          }
        }
        .news-content{
          width: 100%;
          p{
            font-size: .373rem;
            color: #313131;
            line-height: .7rem;
            text-indent: .747rem;
          }
          .img-explain{
            text-align:justify;
            text-align: center;
            font-size:.267rem;
          }
        }
        .img-box{
          width: 100%;
          display: flex;
          justify-content: center;
          img{
            width:100%;
            height: 100%;
          }
        }
      }
      .pagination{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: .53rem;
        .a-more{
          display: flex;
          flex-direction: column;
          font-size:.32rem;
          span{
            display: inline-block;
            width:8.933rem;
            cursor: pointer;
            color:rgba(49,49,49,1);
            height: auto;
            padding:.267rem .32rem;
            border: 1px solid #8A8684;
            border-radius: .267rem;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;
            margin-bottom: .48rem;
          }
        }
        .el-button{
          width: 100%;
          font-size:.427rem;
          border: 1px solid rgba(253,156,2,1);
          padding:.267rem .32rem;
          border-radius: .267rem;
          color:rgba(253,156,2,1);
        }
      }
      // 搜索区域
      // .search-right{
      //   width: 334px;
      //   .el-input{
      //     width: 329px;
      //     height: 45px;
      //   }
      //   .el-input__inner{
      //     border-radius: 5px;
      //   }
      //   .el-input__icon{
      //     font-size: 24px;
      //   }
      //   .hot-news{
      //     .hot-title{
      //       font-size:24px;
      //       margin-bottom: 12px;
      //       color:#313131;
      //       .hot-icon{
      //         display: inline-block;
      //         background: url('../../assets/images/news/hot.png');
      //         width: 18px;
      //         height: 22px;
      //         margin-left: 6px;
      //       }
      //     }
      //     .dg-news{
      //       display: flex;
      //       margin-bottom: 22px;
      //       img{
      //         width:132px;
      //         height:76px;
      //       }
      //       .news-introduce{
      //         flex: 1;
      //         margin-left: 8px;
      //         display: flex;
      //         flex-direction: column;
      //         justify-content: space-between;

      //         .news-title{
      //           font-size:16px;
      //           font-weight: 600;
      //           color: #313131;
      //           box-sizing: border-box;
      //           text-overflow:ellipsis;
      //           display:-webkit-box;
      //           -webkit-box-orient:vertical;
      //           -webkit-line-clamp:2;
      //           overflow: hidden;
      //         }
      //         .time{
      //           font-size:18px;
      //           color: #B5B5B5;
      //           font-weight: 100;
      //         }
      //       }
      //       &:hover{
      //         .news-title{
      //           color:rgba(253,156,2,1);
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}
</style>
